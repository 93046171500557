<app-global-toast></app-global-toast>
<app-global-loader></app-global-loader>
<app-floating-whatsapp-button></app-floating-whatsapp-button>
<router-outlet></router-outlet>
<div class="location-selection-wrapper" *ngIf="showPopupLocation && enableRedirection">
    <div class="location-selection-panel">
        <div class="location-selection-header">
            <h1>Selamat datang di <span class="font-ji">jejak imani</span></h1>
        </div>
        <div class="location-selection-body">
            <p class="mb-3">Silahkan pilih lokasi anda untuk mendapatkan info terbaru mengenai promo yang sedang berlaku</p>
            <app-custom-selector
                [value]="selectedLocation"
                [options]="listLocation"
                [addJiPrefix]="true"
                (valueChange)="onSelectLocationHandler($event)"
                label="Lokasi saya"
            ></app-custom-selector>
            <div class="location-btn">
                <button type="button" (click)="onConfirmPopupAction()">Confirm</button>
                <button type="cancel" class="cancel" (click)="onCancelPopupAction()">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- <app-popup-deals></app-popup-deals> -->